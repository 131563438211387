import React, {
  FC, useEffect, useRef, useState,
} from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Breadcrumbs from '@paradime-io/pragma-ui-kit/lib/components/Breadcrumbs';
import Loader from '@paradime-io/pragma-ui-kit/lib/components/Loader';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Button from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import Card from '@paradime-io/pragma-ui-kit/lib/components/Card';
import Chips from '@paradime-io/pragma-ui-kit/lib/components/Chips';
import Tooltip from '@paradime-io/pragma-ui-kit/lib/components/Tooltip';
import DataTable, { ColumnType } from '@paradime-io/pragma-ui-kit/lib/components/PrimeReactDataTable';
import { useHistory, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Icon } from '@blueprintjs/core';
import { getRelativeTime } from '@paradime/common/lib/common/helpers/time';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import isEqual from 'lodash/isEqual';
import { userAuthStore } from '../../../stores';
import {
  GetScheduleNameQuery,
  GqlParadimeAccountType,
  GqlScheduleTrigger,
  useGetScheduleNameLazyQuery,
  useRunScheduleMutation,
} from '../../../client/generated/service';
import {
  findTimeBetween, triggerToast,
} from '../../../utilis';
import { GlobalErrorType, RunErrorType } from './ScheduleList';
import Errors, { ErrorLevel } from './Errors';
import ScheduleActions from './ScheduleActions';
import ScheduleInsights from './ScheduleInsights';
import { BoltHelp } from '../../Common/AppHelp/help';
import useCancelRunButton from './CancelRunButton';
import { DEFAULT_REFRESH_INTERVAL } from './constants';
import NotFoundZeroState from './NotFoundZeroState';
import { ScheduleSource } from '..';
import { userHasBoltCancelScheduleAccess, userHasBoltTriggerScheduleAccess } from '../../../utilis/PermissionsService';
import ShowScheduleCode from './ShowScheduleCode';
import BoltSchedulesUpgrade from '../../Common/Upgrade/BoltSchedulesUpgrade';
import { useWaitForLDToBeReady } from '../../hooks/useWaitForLDToBeReady';
import ScheduleDetailsCard from './ScheduleDetailsCard';
import PausedScheduleCallout from './PausedScheduleCallout';
import UserInsights from './UserInsights';

export type RunType = NonNullable<NonNullable<NonNullable<GetScheduleNameQuery['getScheduleName']>['runs']>[0]>;
export type InfoType = NonNullable<NonNullable<GetScheduleNameQuery['getScheduleName']>['info']>;

const Schedule: FC = () => {
  const { scheduleId } = useParams<{ scheduleId: string, runId: string }>();
  const [showScheduleCode, setShowScheduleCode] = useState(false);

  const { ldIsReady } = useWaitForLDToBeReady();
  const { flagAppBolt, flagRoiUserInsights } = useFlags();

  const { currentUser } = userAuthStore();
  const { accessLevel } = currentUser as { accessLevel: GqlParadimeAccountType };

  const isRoleWhoCanTriggerRun = userHasBoltTriggerScheduleAccess(accessLevel);
  const isRoleWhoCanCancelRun = userHasBoltCancelScheduleAccess(accessLevel);

  const [runs, setRuns] = useState<RunType[]>([]);
  const [info, setInfo] = useState<InfoType>();
  const [canManualRun, setCanManualRun] = useState(false);
  const [manualRunScheduleNameID, setManualRunScheduleNameID] = useState<number | null>();
  const [isActive, setIsActive] = useState(false);
  const [isSuspended, setIsSuspended] = useState(false);
  const [error, setError] = useState<RunErrorType | null>();
  const [globalError, setGlobalError] = useState<GlobalErrorType | null>();
  const [scheduleNotFound, setScheduleNotFound] = useState(false);

  const [triggeredManually, setTriggeredManually] = useState(false);
  const [timeUntilRefresh, setInternalTimeUntilRefresh] = useState(DEFAULT_REFRESH_INTERVAL);
  const [parentTriggerSchedule, setParentTriggerSchedule] = useState<
    GqlScheduleTrigger | undefined | null>();
  const timeUntilRefreshRef = useRef(DEFAULT_REFRESH_INTERVAL);
  const setTimeUntilRefresh = (newTimeUntilRefresh: number) => {
    timeUntilRefreshRef.current = newTimeUntilRefresh;
    setInternalTimeUntilRefresh(newTimeUntilRefresh);
  };
  const RESULTS_PER_PAGE = 999;

  const history = useHistory();

  const [getScheduleNames, { data: scheduleData }] = useGetScheduleNameLazyQuery({
    variables: {
      limit: RESULTS_PER_PAGE,
      offset: 0,
      scheduleNameUuid: scheduleId,
    },
  });

  const {
    CancelRunDialog,
    onCancelClick,
  } = useCancelRunButton({
    DEFAULT_REFRESH_INTERVAL,
    getScheduleNames,
    setTimeUntilRefresh,
  });

  useEffect(() => {
    let refreshInterval: NodeJS.Timer;
    if (triggeredManually) {
      refreshInterval = setInterval(() => {
        if (timeUntilRefreshRef.current > 0) {
          setTimeUntilRefresh(timeUntilRefreshRef.current - 1);
        } else {
          getScheduleNames();
          setTimeUntilRefresh(DEFAULT_REFRESH_INTERVAL);
        }
      }, 1000);
    }

    return () => {
      if (refreshInterval) clearInterval(refreshInterval);
    };
  }, [triggeredManually, timeUntilRefresh]);

  useEffect(() => {
    getScheduleNames();
  }, [scheduleId]);

  const handleManualRunClick = () => {
    if (manualRunScheduleNameID && scheduleId) {
      triggerRun({
        variables: {
          scheduleNameId: manualRunScheduleNameID,
          scheduleNameUuid: scheduleId,
        },
      });
      setTriggeredManually(true);
    }
  };

  const getRunButtonDisabledState = () => {
    if (triggeredManually) return true;
    if (isSuspended) return true;
    return !canManualRun;
  };

  const [triggerRun, {
    data: triggerRunData,
  }] = useRunScheduleMutation();

  useEffect(() => {
    if (triggerRunData?.runSchedule?.ok) {
      getScheduleNames();
    }
    if (triggerRunData?.runSchedule?.ok === false) {
      triggerToast({
        header: 'Could not run schedule',
        type: 'warning',
        message: triggerRunData?.runSchedule?.errorLog || '',
      });
    }
  }, [triggerRunData]);

  useEffect(() => {
    if (
      scheduleData?.getScheduleName?.runs
      && scheduleData.getScheduleName.meta.totalSize != null
    ) {
      setScheduleNotFound(false);
      const newRuns = scheduleData.getScheduleName.runs.filter(Boolean) as RunType[];
      /** for some reason react is updating twice when adding an array equal to the
       * last one, creating a flicker from run button to refresh, back to run button and back
       * to refresh ¯\_(ツ)_/¯
       */
      if (!isEqual(runs, newRuns)) {
        setRuns(newRuns);
      }
      setParentTriggerSchedule(scheduleData.getScheduleName.scheduleTrigger);
      setInfo(scheduleData.getScheduleName.info);
      setCanManualRun(scheduleData.getScheduleName.canManualRun);
      setManualRunScheduleNameID(scheduleData.getScheduleName.manualRunScheduleNameID);
      setIsActive(scheduleData.getScheduleName.isActive);
      setIsSuspended(scheduleData.getScheduleName.suspended);
      setError(scheduleData.getScheduleName.errorDetails);
      setGlobalError(scheduleData.getScheduleName.globalErrorDetails);
    }

    if (scheduleData && scheduleData.getScheduleName === null) {
      setScheduleNotFound(true);
    }
  }, [scheduleData]);

  useEffect(() => {
    const [firstRun] = runs;
    setTriggeredManually(['Running', 'Starting'].includes(firstRun?.state.text));
  }, [runs]);

  const handleClick = (uuid: string, isCmdClick?: boolean) => {
    const currentElement = document.activeElement;
    if (currentElement?.nodeName !== 'A') {
      if (isCmdClick) {
        window.open(`/bolt/${scheduleId}/${uuid}`, '_blank');
      } else {
        history.push(`/bolt/${scheduleId}/${uuid}`);
      }
    }
  };

  const handleEditSchedule = () => {
    history.push(`/bolt/${scheduleId}/edit`);
  };

  const handleCopySchedule = () => {
    history.push(`/bolt/new-schedule/from/${scheduleId}`);
  };

  const handleCopyScheduleCode = () => {
    setShowScheduleCode(true);
  };

  const columns = [
    { field: 'status', header: 'Status', type: ColumnType.CHIP },
    { field: 'trigger', header: 'Trigger', type: parentTriggerSchedule ? ColumnType.LINK : undefined },
    {
      field: 'branchAndCommit',
      header: 'Branch and commit',
      type: ColumnType.LINK,
      style: { minWidth: '222px' },
    },
    { field: 'lastRun', header: 'Last Run', style: { minWidth: '222px' } },
    { field: 'duration', header: 'Duration', style: { minWidth: '122px' } },
    { field: 'runId', header: 'Run ID', style: { minWidth: '122px' } },
    {
      field: 'cancelButton',
      header: '',
      type: ColumnType.BUTTON,
      frozen: runs.some((run) => {
        // Freeze the column while any run is currently running
        const isRunning = run.state.text === 'Running';
        const isStarting = run.state.text === 'Starting';
        const isCancellable = isRunning && run.canCancel;

        return isStarting || (isCancellable && isRoleWhoCanCancelRun);
      }),
      alignFrozen: 'right' as const,
      style: { minWidth: '122px' },
    },
  ];

  const getBranchAndCommitButton = ({ branch, commit }: RunType) => {
    if (!branch || !commit) return '-';

    return {
      text: `${branch} #${commit.hash?.slice(0, 5)}`,
      href: commit?.url || '#',
      target: 'blank',
    };
  };

  const getCancelButton = (run: RunType) => {
    const isRunning = run.state.text === 'Running';
    const isStarting = run.state.text === 'Starting';
    const isCancellable = isRunning && run.canCancel;

    if (isStarting) {
      return {
        dense: true,
        color: 'default',
        disabled: true,
        loading: true,
        style: { minWidth: '95px' },
      };
    }

    if (isCancellable && isRoleWhoCanCancelRun) {
      return {
        text: 'Cancel',
        icon: 'disable',
        onClick: () => onCancelClick({ ID: run.meta.id, scheduleName: info?.name || '' }),
        dense: true,
        color: 'danger',
      };
    }

    return ' ';
  };

  const mapDataToColumns = (data: RunType[]) => (
    data.map((run) => {
      const {
        state, actor, actorEmail, startDttm, endDttm, parentScheduleRunId,
      } = run;
      return ({
        status: {
          tag: state.text,
          color: state.colorType,
        },
        trigger: parentScheduleRunId && parentTriggerSchedule ? {
          text: `${parentTriggerSchedule?.scheduleName} #${parentScheduleRunId}`,
          href: `/bolt/run_id/${parentScheduleRunId}`,
          target: 'blank',
        } : `${actor}${actorEmail ? ` from ${actorEmail}` : ''}`,
        branchAndCommit: getBranchAndCommitButton(run),
        lastRun: getRelativeTime(DateTime.fromISO(startDttm, { zone: 'utc' })),
        duration: findTimeBetween({ startDate: startDttm, endDate: endDttm }),
        runId: run.meta.id.toString(),
        cancelButton: getCancelButton(run),
      });
    })
  );

  if (ldIsReady && !flagAppBolt) {
    return <BoltSchedulesUpgrade />;
  }

  if (scheduleNotFound) {
    return <NotFoundZeroState />;
  }

  if (
    runs === undefined
    || info === undefined
    || manualRunScheduleNameID === undefined
  ) {
    return <Loader fit />;
  }

  return (
    <>
      {CancelRunDialog}
      <ShowScheduleCode
        scheduleId={scheduleId}
        isVisible={showScheduleCode}
        onHide={() => setShowScheduleCode(false)}
      />
      <AutoLayout
        direction="vertical"
        padding="none"
        distribution="packed"
        alignment="top-left"
        verticalGap="normal"
        wrapperWidth="full"
      >
        <AutoLayout
          direction="horizontal"
          padding="none"
          distribution="space-between"
          style={{ alignItems: 'center' }}
        >
          <Breadcrumbs items={[
            {
              text: 'Schedules',
              onClick: () => history.push('/bolt'),
            },
            {
              text: (
                <Tooltip content={info?.name || ''} targetTagName="div">
                  <div style={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    maxWidth: '40vw',
                    whiteSpace: 'nowrap',
                  }}
                  >
                    {info?.name}
                  </div>
                </Tooltip>
              ),
              disabled: true,
            },
          ]}
          />
          <Chips
            color="primary_alt"
            round={false}
            style={{ margin: '4px', verticalAlign: 'bottom', justifySelf: 'end' }}
            tag="Get help with the UI"
            type="dense"
            view="smooth"
            onClick={() => (
              window.CommandBar.openHelpHub(
                { articleId: BoltHelp.BOLT_HELP_UI },
              )
            )}
          />
        </AutoLayout>
        <AutoLayout
          direction="horizontal"
          padding="none"
          distribution="space-between"
        >
          <AutoLayout
            direction="horizontal"
            padding="none"
            distribution="packed"
            horizontalGap="ultra-dense"
          >
            {
              (scheduleData?.getScheduleName?.source === 'yaml') && (
                <Icon size={12} icon="git-branch" color="var(--grey50)" />
              )
            }
            <Typography
              type="h6"
              style={{ maxWidth: '700px' }}
            >
              {info.name}
            </Typography>
          </AutoLayout>
          <AutoLayout
            padding="none"
            direction="horizontal"
            horizontalGap="very-dense"
          >
            <ScheduleActions
              source={scheduleData?.getScheduleName?.source as ScheduleSource}
              accessLevel={accessLevel}
              actions={{
                onEdit: () => handleEditSchedule(),
                onCopy: () => handleCopySchedule(),
                onCopyCode: () => handleCopyScheduleCode(),
              }}
            />
            {
              isRoleWhoCanTriggerRun
              && isActive
              && !scheduleData?.getScheduleName?.turboCi?.enabled
              && (
                <Button
                  text="Run"
                  icon="play"
                  dense
                  color={triggeredManually ? 'default' : 'primary'}
                  disabled={getRunButtonDisabledState()}
                  onClick={handleManualRunClick}
                  tooltipConfig={isSuspended
                    ? { content: 'You cannot run this schedule because it is paused. Edit the schedule to activate it.' }
                    : undefined}
                  eventContext={Contexts.LOGS}
                  eventObject="refreshScheduleNameClicked"
                  eventAction={Actions.CLICKED}
                />
              )
            }
          </AutoLayout>
        </AutoLayout>
        <Errors globalError={globalError} error={error} errorLevel={ErrorLevel.SCHEDULE_NAME} />
        {isSuspended && <PausedScheduleCallout />}
        {flagRoiUserInsights && <UserInsights />}
        <AutoLayout
          direction="horizontal"
          distribution="packed"
          padding="none"
          horizontalGap="dense"
          style={{ alignItems: 'start', gridTemplateColumns: '1fr 1fr' }}
        >
          <AutoLayout
            direction="vertical"
            padding="none"
            distribution="packed"
            verticalGap="very-dense"
          >
            <ScheduleDetailsCard info={info} scheduleData={scheduleData} />
            <Chips
              color="primary_alt"
              round={false}
              style={{ margin: '4px', verticalAlign: 'bottom', justifySelf: 'end' }}
              tag="Need help with schedule config?"
              type="dense"
              view="smooth"
              onClick={() => (
                window.CommandBar.openHelpHub(
                  { articleId: BoltHelp.BOLT_WRITING_SCHEDULES },
                )
              )}
            />
          </AutoLayout>
          <ScheduleInsights scheduleId={scheduleId} runs={runs} />
        </AutoLayout>
        <Typography
          style={{ marginTop: 40 }}
          type="h6"
        >
          Run history
        </Typography>
        {runs.length > 0 && (
          <DataTable
            columns={columns}
            data={mapDataToColumns(runs)}
            onRowClick={({ index }) => {
              const selectedRun = runs[index!];
              if (selectedRun.state.text !== 'Starting') {
                if (selectedRun.meta.uuid) handleClick(selectedRun.meta.uuid);
              }
            }}
            showSearchBar={false}
            usePagination={runs.length > 20}
            rowsPerPage={20}
            rowsPerPageOptions={[5, 10, 20, 50]}
          />
        )}
        {
          runs.length === 0 && (
            <Card tabIndex={-1}>
              <Typography type="body-small">Run has not been executed</Typography>
            </Card>
          )
        }
      </AutoLayout>
    </>
  );
};

export default Schedule;
