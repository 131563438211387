import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import ModalContainer, { State as ModalState } from '@paradime-io/pragma-ui-kit/lib/components/ModalContainer';
import PageTemplate from '../PageTemplate';
import EnterWorkspaceName from './EnterWorkspaceName';
import GettingStarted from './GettingStarted';
import WorkspaceSettings from './WorkspaceSettings';
import ConnectRepo from '../../../Common/ConnectRepo';
import RepoProvider from '../../../Common/ConnectRepo/RepoProvider';
import Warehouse, { WareHouseModalSource } from '../../../Common/Warehouses';
import { useGetDevProdWarehousesButtons, devProdWarehouseOptions, WarehouseEnv } from '../../../Common/Warehouses/utils';
import PageTitle, { PageTitles } from '../../../PageTitle';

export const createWorkspaceRoutePrefix = 'create-workspace';

export enum CreateWorkspaceRoutes {
  'ENTER_WORKSPACE_NAME' = 'enter-workspace-name',
  'GETTING_STARTED' = 'getting-started',
  'WAREHOUSE' = 'connect-warehouse',
  'WORKSPACE_SETTINGS' = 'workspace-settings',
  'CONNECT_REPO' = 'choose-repo-provider',
  'REPO_PROVIDER' = 'connect-repo',
  // 'COMPLETE' = 'complete',
}

export const CreateWorkspaceSteps: { [key in CreateWorkspaceRoutes]: ModalState } = {
  [CreateWorkspaceRoutes.ENTER_WORKSPACE_NAME]: {
    node: EnterWorkspaceName,
    hasPrevious: false,
  },
  [CreateWorkspaceRoutes.GETTING_STARTED]: {
    node: GettingStarted,
    hasPrevious: false,
  },
  [CreateWorkspaceRoutes.WORKSPACE_SETTINGS]: {
    node: WorkspaceSettings,
    hasPrevious: false,
  },
  [CreateWorkspaceRoutes.CONNECT_REPO]: {
    node: ConnectRepo,
    hasPrevious: false,
  },
  [CreateWorkspaceRoutes.REPO_PROVIDER]: {
    node: RepoProvider,
    hasPrevious: true,
    previousRoute: `/${createWorkspaceRoutePrefix}/${CreateWorkspaceRoutes.CONNECT_REPO}`,
  },
  [CreateWorkspaceRoutes.WAREHOUSE]: {
    node: Warehouse,
    hasPrevious: false,
  },
};

const CreateWorkspace: FC = () => {
  const { pageRef } = useParams<{ pageRef: CreateWorkspaceRoutes }>();

  return (
    <PageTemplate>
      <PageTitle title={PageTitles.WORKSPACE} />
      <ModalContainer
        states={[
          {
            node: CreateWorkspaceSteps[pageRef].node,
            hasPrevious: CreateWorkspaceSteps[pageRef].hasPrevious,
            previousRoute: CreateWorkspaceSteps[pageRef].previousRoute,
          },
        ]}
        initialData={{
          connectRepoNextStepUrl: `/${createWorkspaceRoutePrefix}/${CreateWorkspaceRoutes.REPO_PROVIDER}`,
          repoProviderNextStepUrl: `/${createWorkspaceRoutePrefix}/${CreateWorkspaceRoutes.WAREHOUSE}`,
          noRepoChosenUrl: `/${createWorkspaceRoutePrefix}/${CreateWorkspaceRoutes.CONNECT_REPO}`,
          bigButtons: useGetDevProdWarehousesButtons(),
          env: WarehouseEnv.DEV,
          warehouseOptions: devProdWarehouseOptions,
          newConnection: true,
          source: WareHouseModalSource.CREATE_WORKSPACE,
        }}
        minHeight="medium"
        backdropOpacity="0"
      />
    </PageTemplate>
  );
};

export default CreateWorkspace;
