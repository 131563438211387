import { gql } from '@apollo/client';

gql.disableFragmentWarnings();

export namespace EnvName {
  export const PRODUCTION = 'production';
  export const DEVELOPMENT = undefined;
}

export const getCatalogueDatabaseSettings = gql`
  query getCatalogueDatabaseSettings {
    getCatalogueDatabaseSettings {
      ok
      metadataDatabases {
        name
        isEnabled
      }
      developmentDatabases {
        name
        isEnabled
      }
    }
  }
`;

export const updateCatalogueDevelopmentDatabaseSettings = gql`
  mutation updateCatalogueDevelopmentDatabaseSettings(
    $developmentDatabases: [GQLCatalogueDatabaseInput!]!,
  ) {
    updateCatalogueDevelopmentDatabaseSettings(
      databases: $developmentDatabases,
    ) {
      ok
    }
  }
`;

export const updateCatalogueProductionDatabaseSettings = gql`
  mutation updateCatalogueProductionDatabaseSettings(
    $metadataDatabases: [GQLCatalogueDatabaseInput!]!, 
  ) {
    updateCatalogueProductionDatabaseSettings(
      databases: $metadataDatabases, 
    ) {
      ok
    }
  }
`;

export const catalogueFileContent = gql`
  query catalogueFileContent(
    $catalogueEnvironment: CatalogueEnvironment!,
    $filePath: String!,
    $commitHash: String
  ){
  catalogueFileContent(
    catalogueEnvironment: $catalogueEnvironment
    filePath: $filePath
    commitHash: $commitHash
  ){
      ok
      found
      content
    }
  }
`;

export const catalogueMetaFields = gql`
  query catalogueMetaFields(
    $catalogueEnvironment: CatalogueEnvironment,
    $commitHash: String
  ){
    catalogueMetaFields(
      catalogueEnvironment: $catalogueEnvironment,
      commitHash: $commitHash
    ){
      ok
      fields{
        name
        stringOptions
        boolOptions
        floatOptions
        allOptionsAsJson
      }
    }
  }
`;

export const searchCatalogue = gql`
  query searchCatalogue(
    $query: String!
    $commitHash: String
    $sortOrder: SortOrder!
    $matchName: Boolean!
    $matchDescription: Boolean!
    $matchTags: Boolean!
    $limit: Int
    $offset: Int
    $catalogueEnvironment: CatalogueEnvironment
    $metaFields: [GQLCatalogueSearchMetaField!]
    $includeCategoryIds: [Int!]!
  ){
    searchCatalogue(
      query: $query
      commitHash: $commitHash
      sortOrder: $sortOrder
      matchName: $matchName
      matchDescription: $matchDescription
      matchTags: $matchTags
      limit: $limit
      offset: $offset
      catalogueEnvironment: $catalogueEnvironment
      metaFields: $metaFields
      includeCategoryIDs: $includeCategoryIds
    ) {
        ok
        count
        counts {
          categoryID
          count
          totalCount
          }
        results {
          __typename
          ... on GQLCatalogueDwhColumnItem {
            itemId
            columnName
            tableName
            database
            schema
            dwhType
            description
            tags
            projectName: dbtProjectName
          }
          ... on GQLCatalogueDwhTableItem {
            itemId
            tableName
            database
            schema
            dwhType
            tableType
            rowCount
            sizeInBytes
            lastModified
            description
            tags
            dbtProjectName
          }
          ... on GQLCatalogueMaterialisedDbtItem {
            itemId
            tableName
            database
            schema
            dwhType
            tableType
            rowCount
            sizeInBytes
            lastModified
            description
            tags
            name
            dbtProjectName
          }
          ... on GQLCatalogueMaterialisedDbtObjectColumnItem {
            itemId
            columnName
            tableName
            database
            schema
            dwhType
            description
            tags
            projectName: dbtProjectName
          }
          ... on GQLCatalogueLookerDashboardItem {
            itemId
            itemType
            name
            description
            tags
            fsName
          }
          ... on GQLCatalogueLookerExploreItem {
            itemId
            itemType
            name
            description
            tags
          }
          ... on GQLCatalogueLookerLookItem {
            itemId
            itemType
            name
            description
            tags
            modelName
          }
          ... on GQLCatalogueTableauWorksheetItem {
            itemId
            itemType
            name
            description
            tags
            workbook {
              projectName
            }
          }
          ... on GQLCatalogueTableauDashboardItem {
            itemId
            itemType
            name
            description
            tags
            workbook {
              projectName
            }
          }
          ... on GQLCatalogueTableauDatasourceItem {
            itemId
            itemType
            name
            description
            tags
            downstreamWorkbooks {
              projectName
            }
          }
          ... on GQLCatalogueFivetranConnectorItem {
            itemId
            itemType
            name
            description
            tags
            iconUrl
            icons
            syncFrequency
            connectorName
          }
          ...on GQLCatalogueDBTExposureItem {
            itemId
            itemType
            name
            description
            tags
            type
            maturity
          }
          ...on GQLCatalogueIntegrationDatasourceItem {
            itemId
            itemType
            itemTypeIconName
            name
            description
            tags
            integrationLogoBase64
            databaseName
            extraAttributes {
              name
              value
              iconName
            }
          }
          ...on GQLCatalogueIntegrationDashboardItem {
            itemId
            itemType
            itemTypeIconName
            name
            description
            tags
            integrationLogoBase64
            extraAttributes {
              name
              value
              iconName
            }
          }
          ...on GQLCatalogueIntegrationChartItem {
            itemId
            itemType
            itemTypeIconName
            name
            description
            tags
            integrationLogoBase64
            lastModifiedAt
            extraAttributes {
              name
              value
              iconName
            }
          }
        }
    }
  }
`;

export const getDataCatalogueItem = gql`
fragment columnFields on GQLCatalogueDwhColumnItem {
  itemId
  itemType
  columnName
  tableName
  database
  schema
  dwhType
  tableType
  columnType
  description
  tags
  lineageRef {
    ...lineageRef
  }
  dbtModelPath
  tests
  testStatus
  table {
    ...tableFields
  }
  projectName: dbtProjectName
  __typename
}

fragment materialisedDbtColumnFields on GQLCatalogueMaterialisedDbtObjectColumnItem {
  itemId
  itemType
  columnName
  tableName
  database
  schema
  dwhType
  tableType
  columnType
  description
  tags
  lineageRef {
    ...lineageRef
  }
  dbtModelPath
  tests
  testStatus
  dbtItem {
    ...materialisedDbtFields
  }
  projectName: dbtProjectName
  __typename
}

fragment metaListFields on GQLMetaItem {
  ... on GQLMetaStringValuedItem {
      key
      stringValue
      __typename
    }
    ... on GQLMetaBooleanValuedItem {
      key
      boolValue
      __typename
    }
    ... on GQLMetaFloatValuedItem {
      key
      floatValue
      __typename
    }
    ... on GQLMetaListOfStringValuedItem {
      key
      listOfStringValue
      __typename
    }
    __typename
}

fragment tableFields on GQLCatalogueDwhTableItem {
  dbtMetaAsJson
  dbtMetaAsList {
    ... metaListFields
  }
  primaryIndex
  itemId
  itemType
  tableName
  database
  schema
  dwhType
  tableType
  rowCount
  sizeInBytes
  lastModified
  createdAt
  description
  tags
  materialisation
  dbtProjectName
  lineageRef {
    ...lineageRef
  }
  dbtModelPath
  dependsOn {
    name
    nodeId
    __typename
  }
  referencedBy {
    name
    nodeId
    __typename
  }
  sources
  documentationCoverage
  testsCoverage
  columns {
    dbtMetaAsJson
    dbtMetaAsList {
      ... metaListFields
    }
    itemId
    columnName
    itemType
    columnType
    description
    tags
    tests
    testStatus
    testWithStatusAsList {
      name
      status
      testType
      state
      lastRun
      results
      __typename
    }
    __typename
  }
  __typename
}

fragment materialisedDbtFields on GQLCatalogueMaterialisedDbtItem {
  dbtMetaAsJson
  dbtMetaAsList {
    ... metaListFields
  }
  primaryIndex
  itemId
  itemType
  name
  tableName
  database
  schema
  dwhType
  tableType
  rowCount
  sizeInBytes
  lastModified
  createdAt
  description
  tags
  materialisation
  dbtProjectName
  lineageRef {
    ...lineageRef
  }
  dbtModelPath
  dependsOn {
    name
    nodeId
    __typename
  }
  referencedBy {
    name
    nodeId
    __typename
  }
  sources
  documentationCoverage
  testsCoverage
  dbtColumns: columns {
    dbtMetaAsJson
    dbtMetaAsList {
      ... metaListFields
    }
    itemId
    columnName
    itemType
    columnType
    description
    tags
    tests
    testStatus
    testWithStatusAsList {
      name
      status
      testType
      state
      lastRun
      results
      __typename
    }
    __typename
  }
  __typename
}

fragment lookerDashboardFields on GQLCatalogueLookerDashboardItem {
  itemId
  itemType
  name
  description
  tags
  lineageRef {
    ...lineageRef
  }
  lookerUrl
  fsName
  fsOwnerType
  fsOwnerName
  fsOwnerPicture
  schedules {
    name
    nodeId
    __typename
  }
  sources
  lastViewedAt
  updatedAt
  favouriteCount
  viewCount
  lastUpdaterName
  __typename
}

fragment lookerExploreFields on GQLCatalogueLookerExploreItem {
  itemId
  itemType
  name
  description
  tags
  lineageRef {
   ...lineageRef
  }
  lookerUrl
  modelName
  fields {
    name
    description
    fieldType
    dataType
    viewName
    sql
    __typename
  }
  sources
  __typename
}

fragment lookerLookFields on GQLCatalogueLookerLookItem {
  itemId
  itemType
  name
  description
  tags
  lineageRef {
    ...lineageRef
  }
  lookerUrl
  modelName
  fields {
    name
    description
    fieldType
    dataType
    viewName
    sql
    __typename
  }
  lastAccessedAt
  updatedAt
  exploreName
  favouriteCount
  viewCount
  hasTableCalculations
  sources
  folder
  __typename
}

fragment workbookDetails on GQLCatalogueTableauWorkbook {
  projectName
  siteId
  siteName
  name
  ownerName
}

fragment tableauWorksheetFields on GQLCatalogueTableauWorksheetItem {
  itemId
  itemType
  name
  description
  tags
  lineageRef {
    ...lineageRef
  }
  documentViewId
  path
  worksheetCreatedAt: createdAt
  worksheetUpdatedAt: updatedAt
  url
  datasourceFields {
    fieldId
    name
    fullyQualifiedName
    description
    folderName
    datasourceName
    fieldType
  }
  workbook {
    ...workbookDetails
  }
  __typename
}

fragment tableauDashboardFields on GQLCatalogueTableauDashboardItem {
  itemId
  itemType
  name
  description
  tags
  lineageRef {
    ...lineageRef
  }
  documentViewId
  path
  dashboardCreatedAt: createdAt
  dashboardUpdatedAt: updatedAt
  url
  workbook {
    ...workbookDetails
  }
  referencedByMetrics {
    name
    description
    createdAt
    updatedAt
    ownerName
  }
  __typename
}

fragment tableauDataSourceFields on GQLCatalogueTableauDatasourceItem {
  itemId
  itemType
  name
  description
  tags
  lineageRef {
    ... lineageRef
  }
  datasourceCreatedAt: createdAt
  datasourceUpdatedAt: updatedAt
  url
  hasExtracts
  hasCustomSQL
  extractLastRefreshTime
  extractLastIncrementalUpdateTime
  extractLastUpdateTime
  containsUnsupportedCustomSql
  downstreamWorkbooks {
    ...workbookDetails
  }
  enrichedFields {
    fieldId
    name
    description
    isHidden
    folderName
    tableName
    tableSchema
    databaseName
  }
}

fragment fivetranConnectorFields on GQLCatalogueFivetranConnectorItem {
  itemId
  itemType
  name
  description
  tags
  lineageRef {
    ... lineageRef
  }
  iconUrl
  icons
  owner {
    id
    email
    givenName
    familyName
    picture
    active
  }
  syncFrequency
  schemas {
    id
    nameInSource
    nameInDestination
    url
    tables {
      id
      nameInSource
      nameInDestination
      columns {
        id
        nameInSource
        nameInDestination
        typeInSource
        typeInDestination
        isPrimaryKey
        isForeignKey
      }
    }
  }
  syncStatus
  lastSync
  connectorName
}

fragment dbtExposureFields on GQLCatalogueDBTExposureItem {
  tags
  ownerName
  ownerEmail
  maturity
  type
  description
  dbtMetaAsJSON
  dbtMetaAsList {
    ... metaListFields
  }
  url
  lineageRef {
    name
    nodeId
    commitHash
    __typename
    
  }
  name
  itemId
  itemType
  sources
}

fragment integrationDatasourceFields on GQLCatalogueIntegrationDatasourceItem {
  itemId
  itemType
  name
  description
  tags
  lineageRef {
    ...lineageRef
  }
  itemTypeLabel
  itemTypeIconName
  integrationName
  integrationLogoBase64
  createdAtTimestamp: createdAt
  url
  databaseName
  schemaName
  tableName
  fields {
    name
    type
    description
    dataType
    extraAttributes {
      name
      value
      iconName
    }
  }
  extraAttributes {
    name
    value
    iconName
  }
}

fragment integrationDashboardItem on GQLCatalogueIntegrationDashboardItem {
  itemId
  itemType
  name
  description
  tags
  lineageRef {
    ...lineageRef
  }
  itemTypeLabel
  itemTypeIconName
  integrationName
  integrationLogoBase64
  createdAtTimestamp: createdAt
  lastModifiedAt
  url
  ownedBy
  extraAttributes {
    name
    value
    iconName
  }
}

fragment integrationChartItem on GQLCatalogueIntegrationChartItem {
  itemId
  itemType
  name
  description
  tags
  lineageRef {
    ...lineageRef
  }
  itemTypeLabel
  itemTypeIconName
  integrationName
  integrationLogoBase64
  createdAtTimestamp: createdAt
  lastModifiedAt
  url
  ownedBy
  fields {
    name
    type
    description
    dataType
    extraAttributes {
      name
      value
      iconName
    }
  }
  extraAttributes {
    name
    value
    iconName
  }
}

fragment lineageRef on GQLCatalogueLineageReference {
  name
  nodeId
  commitHash
  __typename
}

query getDataCatalogueItem(
  $commitHash: String,
  $itemId: String!,
  $catalogueEnvironment: CatalogueEnvironment
  $filter: CatalogueTreeFilter,
  ) {
  getDataCatalogueItem(
    commitHash: $commitHash
    itemId: $itemId
    catalogueEnvironment: $catalogueEnvironment
  ) {
    ok
    found
    item {
      __typename
      ... columnFields
      ... tableFields
      ... materialisedDbtFields
      ... materialisedDbtColumnFields
      ... lookerDashboardFields
      ... lookerExploreFields
      ... lookerLookFields
      ... tableauWorksheetFields
      ... tableauDashboardFields
      ... tableauDataSourceFields
      ... fivetranConnectorFields
      ... dbtExposureFields
      ... integrationDatasourceFields
      ... integrationDashboardItem
      ... integrationChartItem
    }
    __typename
  }
  getCatalogueTreeIDPathFromItemID(
    catalogueEnvironment: $catalogueEnvironment
    filter: $filter
    itemId: $itemId
  ) {
    ok
    found
    treeItemIDPath
  }
}
`;

export const getDataCatalogueItemFromLineageId = gql`
  query getDataCatalogueItemFromLineageId(
    $commitHash: String,
    $lineageId: String!,
    $catalogueEnvironment: CatalogueEnvironment
  ){
    getDataCatalogueItemFromLineageId(
      commitHash: $commitHash,
      lineageId: $lineageId,
      catalogueEnvironment: $catalogueEnvironment
    ){
      ok
      found
      item {
        __typename
        ...on GQLCatalogueDwhColumnItem{
          itemId
        }
        ...on GQLCatalogueDwhTableItem{
          itemId
        }
        ...on GQLCatalogueMaterialisedDbtItem{
          itemId
        }
        ...on GQLCatalogueLookerDashboardItem{
          itemId
        }
        ...on GQLCatalogueMaterialisedDbtObjectColumnItem{
          itemId
        }
        ...on GQLCatalogueLookerExploreItem{
          itemId
        }
        ...on GQLCatalogueLookerLookItem{
          itemId
        }
        ...on GQLCatalogueTableauWorksheetItem{
          itemId
        }
        ...on GQLCatalogueTableauDashboardItem{
          itemId
        }
        ...on GQLCatalogueTableauDatasourceItem{
          itemId
        }
        ...on GQLCatalogueFivetranConnectorItem{
          itemId
        }
        ...on GQLCatalogueDBTExposureItem{
          itemId
        }
        ...on GQLCatalogueIntegrationDatasourceItem{
          itemId
        }
        ...on GQLCatalogueIntegrationDashboardItem{
          itemId
        }
        ...on GQLCatalogueIntegrationChartItem{
          itemId
        }
      }
    }
  }
`;

export const expandCatalogueTree = gql`
  query expandCatalogueTree(
    $catalogueEnvironment: CatalogueEnvironment,
    $parentItemID: String,
    $limit: Int!,
    $offset: Int!,
    $filter: CatalogueTreeFilter,
  ) {
    expandCatalogueTree(
      catalogueEnvironment: $catalogueEnvironment
      parentItemID: $parentItemID
      limit: $limit
      offset: $offset
      filter: $filter
    ) {
      items {
        itemID
        type
        label
        hasChildren
        catalogueId
        icon
        logoBase64
      }
    }
  }
`;

export const listCatalogueAttachments = gql`
query listCatalogueAttachments(
  $itemId: String!
){
  listCatalogueAttachments(
    itemId: $itemId
  ){
    ok
    attachments{
      key
      value
    }
  }
}
`;

export const setCatalogueAttachment = gql`
mutation setCatalogueAttachment(
  $itemId: String!,
  $key: String!,
  $value: String!
){
  setCatalogueAttachment(
    itemId: $itemId
    key: $key
    value: $value
  ){
    ok
  }
}
`;

export const getCatalogueState = gql`
query getCatalogueState($catalogueEnvironment: CatalogueEnvironment) {
  getCatalogueState(catalogueEnvironment: $catalogueEnvironment) {
    ok
    state
    hasLastRefreshTime
    lastRefreshDttmUTC
    currentRefreshStartDttmUTC
    lastErrorMessage
    errorDetails {
      humanErrorMessage
      rawErrorMessage
      errorMessageTitle
      errorMessageDocsUrl
      errorMessageSeverity {
        colorType
        text
      }
    }
  }
}
`;

export const refreshCatalogue = gql`
  mutation refreshCatalogue {
  refreshCatalogue {
    ok
  }
}
`;

export const getSignalTable = gql`
  query getSignalTable(
    $signal: String!,
    $from: Int!,
    $size: Int!,
    $isIgnored: Boolean!,
    $isResolved: Boolean!,
    $order: RadarSortOrder!
  ){
    getSignalTable(
      signal: $signal,
      from: $from,
      size: $size,
      isIgnored: $isIgnored,
      isResolved: $isResolved,
      order: $order
    ){
      ok
      headers{
        id
        name
      }
      meta{
        hasWorkflow
        pages
        owner
        severity
        recipients
        resultCount
      }
      rows{
        id
        hash
        cells {
          headerId
          type
          subtype
          value
        }
        metaCells{
          key
          value
        }
      }
    }
  }
`;

export const markSignalsIgnored = gql`
  mutation markSignalsIgnored(
    $signal: String!
    $hashes: [String!]!
    $isIgnored: Boolean!
  ){
    markSignalsIgnored(
      signal: $signal,
      hashes: $hashes,
      isIgnored: $isIgnored
    ) { ok }
  }
`;

export const computeLineage = gql`
query computeLineage(
  $commitHash: String!,
  $nodeName: String!,
  $limitLevels: Int,
  $limitUpstreamLevels: Int,
  $limitDownstreamLevels: Int,
  $upstreamSelector: String,
  $downstreamSelector: String,
  ){
  computeLineage(
    commitHash: $commitHash,
    nodeName: $nodeName,
    limitLevels: $limitLevels,
    limitUpstreamLevels: $limitUpstreamLevels,
    limitDownstreamLevels: $limitDownstreamLevels,
    upstreamSelector: $upstreamSelector,
    downstreamSelector: $downstreamSelector,
  ){
    ok
    results{
      nodeId
      nodeName
      parentNodeIds
      filePath
      url
      nodeType
      tags
      canonicalName{
        name
        packageName
      }
      attributes {
        ... on GQLLineageNodeFivetranAttributes {
         sourceLogoURL
        }
        ... on GQLLineageNodeDbtAttributes {
          packageName
          isInPrimaryPackage
          isInPrimaryWorkspace
          access
          workspaceRelation
         }
     }
      logoBase64
      color
      integrationName
      nodeTypeLabel
    }
    summary {
      dbtPackageNames
    }
  }
}
`;

export const searchLineage = gql`
  query searchLineage($commitHash: String!, $filePath: String, $nodeId: String){
    searchLineage(
      commitHash: $commitHash,
      filePath: $filePath,
      nodeId: $nodeId
    ){
      ok
      results{
        nodeId
        nodeName
        canonicalName{
          name
          packageName
        }
        logoBase64
      }
    }
  }
`;

export const branchDiffLineage = gql`
  query branchDiffLineage($leftCommitHash: String!, $rightCommitHash: String!){
    branchDiffLineage(
      leftCommitHash: $leftCommitHash,
      rightCommitHash: $rightCommitHash
    ){
      ok
      results{
        nodeChanged
        unionParentIds
        left{
          nodeId
          nodeName
          nodeType
          filePath
          url
          tags
          canonicalName{
            name
            packageName
          }
        }
        leftParentIds
        right{
          nodeId
          nodeName
          nodeType
          filePath
          url
          tags
          canonicalName{
            name
            packageName
          }
        }
        rightParentIds
      }
    }
  }
`;

export const searchNodes = gql`
  query searchNodes($commitHash: String!, $query: String!) {
    searchLineage(commitHash: $commitHash, searchName: $query) {
      ok
      results {
        nodeType
        nodeTypeLabel
        tags
        filePath
        url
        nodeId
        canonicalName {
          name
          packageName
        }
        logoBase64
        color
      }
    }
  }
`;

export const scheduleCommandLogs = gql`
  subscription scheduleCommandLogs($commandID: Int!) {
    scheduleCommandLogs(commandID: $commandID) {
      ok
      finished
      stdout
    }
  }
`;

export const AiGenerateCatalogDescriptions = gql`
query aiGenerateCatalogDescriptions($filePath: String!) {
  aiGenerateCatalogDescriptions(filePath: $filePath) {
    ok
    traceId
    description
    columns {
      name
      description
    }
  }
}
`;

export const listCatalogueFilterCategories = gql`
  query listCatalogueFilterCategories {
    listCatalogueFilterCategories {
      ok
      sections {
        heading
        logoBase64
        integrationUid
        icon
        categories {
          id
          label
          icon
        }
      }
    }
  }
`;

export const getSlackUsers = gql`
  query getSlackUsers($refresh: Boolean) {
      getSlackUsers(refresh: $refresh) {
          ok
          users {
              id
              email
              statusText
              statusEmoji
              avatars {
                  original
                  image48
                  image24 
                  image32
                  image72
                  image192
              }
              name
              realName
          }
      }
  }
`;

export const getSlackChannels = gql`
  query getSlackChannels($refresh: Boolean) {
      getSlackChannels(refresh: $refresh) {
          ok 
          channels {
              id
              name
          }
      }
  }
`;
