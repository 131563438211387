/* eslint-disable camelcase */
import React, {
  FC,
  useState,
} from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import * as S from '../Common/Layouts/AppPage.styles';
import Sidebar from './Sidebar';
import PageTitle, { PageTitles } from '../PageTitle';
import ThoughtSpotDbtSync from './ThoughtSpotDbtSync';
import TurboCi from './TurboCi';
import TurboCd from './TurboCd';
import Scheduler from './Scheduler';
import Webhooks from './Webhooks';
import {
  userHasAccountSettingsWebhooksAccess,
} from '../../utilis/PermissionsService';
import { userAuthStore } from '../../stores';
import { GqlParentScheduleTrigger } from '../../client/generated/service';

export enum BoltTab {
  BOLT = 'bolt',
  THOUGHTSPOT = 'thoughtSpot',
  TURBOCI = 'turbo-ci',
  TURBOCD = 'turbo-cd',
  WEBHOOK = 'webhook',
}

export type NotifyCondition = 'failed' | 'passed';

export type ScheduleSource = 'yaml' | 'ui';

export type ScheduleKindType = 'standard' | 'turbo_ci' | 'deferred';

export type ScheduleOperationTypes = 'list'|'add'|'update'|'copy'|'delete'|'off'|'off-all'|'refresh'|'view-code';

export type ScheduleType = {
  uuid?: string,
  entryId?: number | null,
  name: string,
  schedule: string,
  source: ScheduleSource,
  deferredSchedule?: {
    enabled: boolean,
    deferredScheduleName?: string | null,
    successfulRunOnly?: boolean,
  }
  turboCi?: {
    enabled: boolean,
    deferredManifestSchedule?: string | null,
    successfulRunOnly?: boolean,
  }
  commands: string[],
  gitBranch?: string | null,
  owner?: string | null,
  slackOn?: NotifyCondition[],
  slackNotify?: string[],
  emailOn?: NotifyCondition[],
  emailNotify?: string[],
  scheduleTrigger?: GqlParentScheduleTrigger,
  suspended?: boolean,
}

const Bolt: FC = () => {
  const { path } = useRouteMatch();
  const [isLeftPanelOpen, setIsLeftPanelOpen] = useState(false);
  const { currentUser: { accessLevel } } = userAuthStore.getState();

  return (
    <S.Page
      isLeftPanelOpen={isLeftPanelOpen}
    >
      <PageTitle title={PageTitles.BOLT} />
      <S.LeftSidebar>
        <Sidebar
          isLeftPanelOpen={isLeftPanelOpen}
          setIsLeftPanelOpen={setIsLeftPanelOpen}
        />
      </S.LeftSidebar>
      <S.Body>
        <Switch>
          <Route exact path={`${path}/${BoltTab.THOUGHTSPOT}`} component={ThoughtSpotDbtSync} />
          <Route exact path={`${path}/${BoltTab.TURBOCI}`} component={TurboCi} />
          <Route exact path={`${path}/${BoltTab.TURBOCD}`} component={TurboCd} />
          {userHasAccountSettingsWebhooksAccess(accessLevel) && (
            <Route exact path={`${path}/${BoltTab.WEBHOOK}`} component={Webhooks} />
          )}
          <Route path={path} component={Scheduler} />
        </Switch>
      </S.Body>
    </S.Page>
  );
};
export default Bolt;
