import React, { FC, useEffect, useState } from 'react';
import { History } from 'history';
import AppNavbar from '@paradime-io/pragma-ui-kit/lib/components/AppNavbar';
import CommandBarLauncher from '@paradime-io/pragma-ui-kit/lib/components/CommandBarLauncher';
import { useHistory } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { IconName } from '@blueprintjs/core';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import useListenForThemeChange from './hooks/useListenForThemeChange';
import useNavbarActionsListener from './hooks/useNavbarActionsListener';
import useListWorkspaces from '../Platform/Workspaces/hooks/useListWorkspaces';
import useSetWorkspace from '../Platform/Workspaces/hooks/useSetWorkspace';
import KnockFeed from '../Common/Libraries/Knock/KnockFeed';
import { useGetNavbarProfile } from './hooks/useGetNavbarProfile';
import { userAuthStore } from '../../stores';
import { userHasAccountSettingsAccess, userHasAccountSettingsConnectionsAccess } from '../../utilis/PermissionsService';
import CalloutSandboxWorkspace from './CalloutSandboxWorkspace';
import { useGetAppStatus } from './hooks/useGetAppStatus';

const getNavMenus = (
  dark: boolean,
  history: History,
) => {
  const { accessLevel } = userAuthStore((s) => s.currentUser);
  return [
    {
      icon: 'help' as IconName,
      dark,
      eventObject: 'helpNav',
      menu: [],
      onClick: () => {
        if (window.analytics?.track) window.analytics.track('app:helphub_viewed');
        window.CommandBar.openHelpHub();
      },
    },
    ...userHasAccountSettingsAccess(accessLevel) ? [{
      icon: 'cog' as IconName,
      dark,
      eventObject: 'accountSettingsNav',
      menu: [],
      onClick: () => {
        if (window.analytics?.track) window.analytics.track('accountSettings:connectionTab_viewed');
        const page = userHasAccountSettingsConnectionsAccess(accessLevel)
          ? AccountSettingsTab.CONNECTIONS : AccountSettingsTab.INTEGRATIONS;
        history.push(`/account-settings/${page}`);
      },
    }] : [],
  ];
};

export enum ProfileTab {
  'PROFILE' = 'profile',
  'PRIVACY' = 'privacy',
  'ENV_VARIABLES' = 'env-variables',
}

export enum AccountSettingsTab {
  'CONNECTIONS' = 'connections',
  'WORKSPACE' = 'workspace',
  'INTEGRATIONS' = 'integrations',
  'TEAM' = 'team',
  'BILLING' = 'billing',
  'PLANS' = 'plans',
  'WEBHOOKS' = 'webhooks',
}

interface NavBarProps {
  currentApp: string,
}

const NavBar: FC<NavBarProps> = ({ currentApp }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const history = useHistory();
  const { boot: bootIntercom } = useIntercom();
  const dark = useListenForThemeChange();
  useNavbarActionsListener();
  const navbarProfileMenu = useGetNavbarProfile(isDarkMode);
  const { appStatus } = useGetAppStatus();

  const { joinedWorkspacesList, currentWorkspace } = useListWorkspaces();
  const { setCurrentWorkspace } = useSetWorkspace({
    onCompleted: () => window.location.reload(),
  });

  useEffect(() => {
    setIsDarkMode(dark);
  }, [dark]);
  // const { alternativeLogo } = useSeasonalChanges(dark);

  useEffect(() => {
    bootIntercom({
      hideDefaultLauncher: true,
    });
  }, []);

  const NavBarChildElements = () => (
    <AutoLayout
      direction="horizontal"
      distribution="packed"
      padding="none"
      horizontalGap="very-dense"
      alignment="center"
      style={{ alignItems: 'center' }}
    >
      <CommandBarLauncher dark={dark} />
    </AutoLayout>
  );

  const NavBarRightElements = [
    (<KnockFeed darkMode={isDarkMode} />),
  ];

  return (
    <>
      <div
        id="paradime-navbar"
        style={{
          width: '100vw',
          gridArea: 'n',
          zIndex: 20,
        }}
      >
        <AppNavbar
          dark={isDarkMode}
          key="navbar"
          navbarMenus={getNavMenus(isDarkMode, history)}
          navbarProfileMenu={navbarProfileMenu}
          currentAppName={currentApp}
          widgets={NavBarChildElements()}
          widgetsRight={NavBarRightElements}
          workspaceDropdownProps={{
            workspaceList: joinedWorkspacesList.map(
              ({ name, uid }) => ({ label: name, value: uid }),
            ),
            onChange: ({ value }) => {
              setCurrentWorkspace(value);
            },
            initialWorkspaceSelected: {
              label: currentWorkspace?.name, value: currentWorkspace?.uid,
            },
            withActionMenuItem: true,
            onActionMenuItemClick: () => history.push('/platform/workspaces'),
          }}
          appStatus={appStatus}
          homePath="/home"
        />
        {
          (currentApp !== 'Platform') && (
            <CalloutSandboxWorkspace />
          )
        }
      </div>
    </>
  );
};

export default NavBar;
